// 接口服务器地址
export const API_BASEURL = '/sc/client'

// 本地化键名
export const LOCAL_CONST_NAME = 'rdjc_website'

// 协议路径
export const AGGREMENT_URL = 'https://www.rongdajicai.com/'

export const PHONEREG = /^1[3-9]\d{9}$/

let constantObj = {
  API_BASEURL,
  LOCAL_CONST_NAME,
  PHONEREG,
  AGGREMENT_URL
}

export default constantObj
