<template>
  <a-config-provider :locale="locale" :theme="{ token: { colorPrimary: '#00CCA0' } }">
    <div class="big-container">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import locale from 'ant-design-vue/es/locale/zh_CN'
import { RouterView } from 'vue-router'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'App',
  data() {
    return {
      locale,
    }
  },
  methods: {
    //每次刷新浏览器的时候，获取一下最新的地址树列表，在往后的调用时不需要每次获取，以免影响加载效率
    //因为地址树在login页面和main页面都有用到，所以将此方法放在app.vue文件下获取
    ...mapActions(['get_districtTree'])
  },
  mounted() {
    this.get_districtTree()
  }
}
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
/* .ant-select .ant-select-selector{
  border: none !important;
} */
.pointer {
  cursor: pointer;
}
.ant-btn-primary {
  background-color: #00cca0;
}

.big-container {
  width: 100%;
}
</style>
