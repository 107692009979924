import storage from '@/utils/storage'

const productsCart = {
  state() {
    return {
      productsCart: storage.get('productsCart') || []
    }
  },
  mutations: {
    SAVE_productsCart(state, productsCart) {
      state.productsCart = productsCart
      // storage.set('productsCart', productsCart)
    },
    // CLEAR_productsCart(state, productsCart) {
    //   state.productsCart = ''
    //   // storage.set('productsCart', [])
    // }
  },
  actions: {
    set_productsCart({ state, commit }, payload) {
      commit('SAVE_productsCart', payload)
      storage.set('productsCart', payload)
    },

    clear_productsCart({ state, commit }) {
      commit('SAVE_productsCart', [])
      storage.set('productsCart', [])
    },
  }
}

export default productsCart
