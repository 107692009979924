import storage from '@/utils/storage'
import api from '@/net/api'

const user = {
  state() {
    return {
      userInfo: storage.get('userInfo') || {},
      navsList: storage.get('navsList') ||[]//顶部菜单
    }
  },
  mutations: {
    SET_userInfo(state, payload) {
      state.userInfo = payload
    },
    SET_navsList(state, payload) {
      state.navsList = payload
    },
  },
  actions: {
    set_userInfo({ state, commit }, payload) {
      commit('SET_userInfo', payload)
      storage.set('userInfo', payload)
    },

    clear_userInfo({ state, commit }) {
      commit('SET_userInfo', {})
      storage.set('userInfo', {})
    },
    set_navsList({ state, commit }, payload) {
      commit('SET_navsList', payload)
      storage.set('navsList', payload)
    },
    clear_navsList({ state, commit }) {
      commit('SET_navsList', [])
      storage.set('navsList', [])
    },
    get_userInfo({ state, dispatch }) {
      api
        .get_sysUser_userInfo()
        .then(res => {
          let isCollege = res.businessType==='BUYER'&&res.businessLevel==='ADMIN' && res.auditState==='PASSED'
          ||res.businessType==='BUYER'&&res.businessLevel==='RESTAURANT'
          ||res.businessType==='BUYER'&&res.businessLevel==='WAREHOUSE'
          ||res.businessType==='BUYER'&&res.businessLevel==='STORE'
          let isStore = res.businessType==='BUYER'&&res.businessLevel==='STORE'//是班组身份
          let isOrgwarehouse = res.businessType==='BUYER'&&res.businessLevel==='ADMIN' && res.auditState==='PASSED'//是单独学校身份
          let isRestaurant = res.businessType==='BUYER'&&res.businessLevel==='RESTAURANT'//是餐厅身份
          let isWarehouse = res.businessType==='BUYER'&&res.businessLevel==='WAREHOUSE'|| res.businessType==='BUYER'&&res.businessLevel==='ORGWAREHOUSE'//是仓库身份
          res.isWarehouse = isWarehouse
          res.isRestaurant = isRestaurant
          res.isOrgwarehouse = isOrgwarehouse
          res.isStore = isStore
          res.isCollege = isCollege
          res.isSupplier = res.businessType==='SUPPLIER'&&res.businessLevel==='ADMIN' && (res.auditState==='PASSED'||res.auditState==='AUDITING')
          dispatch('set_userInfo', res || {})
          if(isCollege||isWarehouse ){
            let arr = [
              { title: '首页', label: '首页', router: '/main/home' },
              { title: '采购商城', label: '采购商城', router: '/main/buyMallNew' }
            ].map(v => ({ ...v, key: v.router }))
            dispatch('set_navsList',arr)
          }else{
            let arr = [
              { title: '首页', label: '首页', router: '/main/home' },
              { title: '采购商城', label: '采购商城', router: '/main/buyMall' }
            ].map(v => ({ ...v, key: v.router }))
            dispatch('set_navsList',arr)
          }
        })
        .catch(err => {
          console.log('err', err)
        })
    }
  }
}

export default user
