import { createRouter, createWebHashHistory } from 'vue-router'
import * as VueRouter from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/main/home',
    component: VueRouter.RouterView,
    children: [
      {
        path: '/login',
        component: () => import('@/pages/login/index')
      },
      {
        path: '/main',
        component: () => import('@/pages/main/index'),
        children: [
          {
            path: '/main/home',
            component: () => import('@/pages/main/home/index')
          },
          {
            path: '/main/buyMall',
            component: () => import('@/pages/main/buyMall/index')
          },
          {
            path: '/main/buyMallNew',
            component: () => import('@/pages/main/buyMallNew/index')
          },
          {
            path: '/main/personalCenter',
            component: () => import('@/pages/main/personalCenter/index'),
            children: [
              {
                path: '/main/personalCenter/userInfo',
                component: () => import('@/pages/main/personalCenter/userInfo/index')
              },
              {
                path: '/main/personalCenter/order',
                component: () => import('@/pages/main/personalCenter/order/index')
              },
              {
                path: '/main/personalCenter/cart',
                component: () => import('@/pages/main/personalCenter/cart/index')
              },
              {
                path: '/main/personalCenter/collect',
                component: () => import('@/pages/main/personalCenter/collect/index')
              },
              {
                path: '/main/personalCenter/supplier',
                component: () => import('@/pages/main/personalCenter/supplier/index')
              },
              {
                path: '/main/personalCenter/orgRegister',
                component: () => import('@/pages/main/personalCenter/orgRegister/index')
              },
              {
                path: '/main/personalCenter/store',
                component: () => import('@/pages/main/personalCenter/store/index')
              },
              {
                path: '/main/personalCenter/finance',
                component: () => import('@/pages/main/personalCenter/finance/index')
              },
              {
                path: '/main/personalCenter/company',
                component: () => import('@/pages/main/personalCenter/company/index')
              },
              {
                path: '/main/personalCenter/message',
                component: () => import('@/pages/main/personalCenter/message/index')
              },
              {
                path: '/main/personalCenter/account',
                component: () => import('@/pages/main/personalCenter/account/index')
              },
              {
                path: '/main/personalCenter/address',
                component: () => import('@/pages/main/personalCenter/address/index')
              },
              {
                path: '/main/personalCenter/companyPeriod',
                component: () => import('@/pages/main/personalCenter/companyPeriod/index')
              }
            ]
          },
          {
            path: '/main/productInfo',
            component: () => import('@/pages/main/productInfo/index')
          },
          {
            path: '/main/priceTrend',
            component: () => import('@/pages/main/priceTrend/index')
          },
          {
            path: '/main/news',
            component: () => import('@/pages/main/news/index')
          },
          {
            path: '/main/notices',
            component: () => import('@/pages/main/notices/index')
          },
          {
            path: '/main/store/list',
            component: () => import('@/pages/main/store/list/index')
          },
          {
            path: '/main/store/detail',
            component: () => import('@/pages/main/store/detail/index')
          },
          {
            path: '/main/order/submit',
            component: () => import('@/pages/main/order/submit/index')
          },
          {
            path: '/main/order/pay',
            component: () => import('@/pages/main/order/submit/pay')
          },
          {
            path: '/main/order/detail',
            component: () => import('@/pages/main/order/detail/index')
          },
          {
            path: '/main/companyPeriodPay/pay',
            component: () => import('@/pages/main/companyPeriodPay/pay')
          }
        ]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
