import storage from '@/utils/storage'

const city = {
  state() {
    return {
      currentCity: storage.get('currentCity') || {
        district: '潍坊市',
        code: 370700
      }
    }
  },
  mutations: {
    SAVE_currentCity(state, city) {
      state.currentCity = city
      storage.set('currentCity', city)
    }
  }
}

export default city
