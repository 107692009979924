import { get, post, put,del } from './helper/axios'

let api = {
  //--------------------------------------------------------------------------------------------------登录
  //获取短信验证码
  get_gate_phoneCode: params => get('/gate/phoneCode', { params }),
  //电话登录
  get_gate_phoneLogin: data => post('/gate/phoneLogin', data),
  //账号密码登录
  get_gate_accountLogin: data => post('/gate/login', data),
  //退出
  get_sysuser_logout: params => get('/sysUser/logout', { params }),
  //用户信息
  get_sysUser_userInfo: params => get('/sysUser/info', { params }),
  // 编辑用户信息
  edit_sysUser_userInfo: data => post('/sysUser/edit', data),
  // 更改手机号
  get_sysUser_changePhone: data => post('/sysUser/changePhone', data),
  // 更改密码
  get_sysUser_changePassword: data => post('/sysUser/changePassword', data),
  // 区域列表-下拉搜索
  get_plat_districtTree: params => get('/plat/index/districtTree', { params }),
  // 忘记密码
  get_sysUser_resetPassword: data => post('/gate/resetPassword', data),

  //--------------------------------------------------------------------------------------------------注册、认证
  // 注册(学校/企业)
  plat_register_org: data => post('/plat/index/register/org', data, { needHandle: true }),
  // 注册供应商
  plat_register_supplier: data => post('/plat/index/register/supplier', data, { needHandle: true }),
  // 获取学校/企业信息
  get_buyer_org_info: params => get('/buyer/org/info', { params }),
  // 编辑
  edit_buyer_org_info: data => post('/buyer/org/edit', data),
  // 认证学校
  auth_buyer_org_info: data => post('/buyer/org/authOrg', data),
  // 供应商详情
  get_supplier_info: params => get('/supplier/info', { params }),
  // 编辑供应商
  edit_supplier_info: data => post('/supplier/edit', data),
  // 认证供应商
  auth_supplier_info: data => post('/supplier/authSupplier', data),

  //--------------------------------------------------------------------------------------------------收货地址
  // 收货地址列表
  get_address_list: params => get('/buyer/org/address/list', { params }),
  //学校身份的收货地址列表
  get_isCollege_address_list: params => get('/buyer/org/address/info', { params }),
  // 新增收获地址
  save_address_info: data => post('/buyer/org/address/save', data),
  // 收货地址设置默认
  set_address_default: params => get('/buyer/org/address/default', { params }),
  // 查询收货地址信息
  get_address_info: params => get('/buyer/org/address/infoNew', { params }),
  // 编辑收货地址
  edit_address_info: data => post('/buyer/org/address/edit', data),
  // 删除收获地址信息
  del_address_info: params => get('/buyer/org/address/del', { params }),

  //--------------------------------------------------------------------------------------------------账号管理
  // 账号列表
  get_account_list: params => get('/sysUser/company/user/list', { params }),
  // 企业账号-创建
  save_account_info: data => post('/sysUser/company/user/save', data),
  // 企业账号-编辑
  edit_account_info: data => post('/sysUser/company/user/edit', data),
  // 删除
  del_account_info: params => get('/sysUser/company/user/del', { params }),

   // 七牛云token
   qiniuyun_upload: data => post('/file/upload/auth', data),

  //已开通区域
  get_plat_openDistrict: params => get('/plat/index/openDistrict', { params }),
  //区域商品筛选条件
  get_plat_districtProductSearch: params => get('/plat/index/districtProductSearch', { params }),
  // 商品分类
  get_plat_index_getProductCategory: params => get('/plat/index/getProductCategory', { params }),
  // 首页——供应商
  post_plat_index_supplier: data => post('/plat/index/supplier', data),
  // 首页——热销商品
  post_plat_index_getProduct: data => post('/plat/index/getProduct', data),
  // 关键词筛选--热门搜索
  get_plat_searchKeyword: params => get('/plat/index/searchKeyword', { params }),
  // 新增关键词筛选
  get_plat_addSearchKeyword: params => get('/plat/index/addSearchKeyword', { params }),
  // 经常购买
  get_plat_recentPurchaseProduct: params => get('/buyer/product/plat/recentPurchaseProduct', { params }),
  // 轮播图
  get_config_banner: params => get('/plat/index/banner', { params }),
  // 舆情信息
  get_OfficialWebsite_news_list: data => post('/OfficialWebsite/news/list', data),
  // 价格走势
  get_product_priceTrend_list: params => get('/OfficialWebsite/price/trend', { params }),///product/priceTrend/list
  // 公告消息
  get_buyer_index_advice_list: params => get('/buyer/index/advice/list', { params }),
  // 其他变更消息
  get_config_advice_list: params => get('/config/advice/list', { params }),
  // 平台公告消息
  get_config_notic_list: params => get('/config/notic/list', { params }),
  // 创建订单
  post_plat_order_create: data => post('/plat/order/create', data),
  //高校提交订单
  post_buyer_order_create: data => post('/buyer/order/create', data),
  // 商品详情
  get_buyer_product_info: params => get('/buyer/product/info', {params}),
  // 供应商详情
  get_buyer_supplier_info: params => get('/buyer/supplier/info', {params}),
  // 商品评价
  get_buyer_product_comment_list: data => post('/buyer/product/comment/list', data),
  // 收藏/取消收藏
	post_product_favorites_deal:  data => post( "/buyer/product/favorites/deal" , data),
  // 店内推荐商品/最新发布
  get_plat_index_recommend_product: params => get('/plat/index/recommend/product', {params}),
  // 收藏列表
	post_product_favorites_list:  data => post( "/buyer/product/favorites/list" , data),
  // 发起支付
  postPayOrder:  data => post( "/plat/order/request" , data),
  // 查询订单支付状态
  postPayState:  params => get( "/plat/order/payState" , {params}),
  // 搜索页——供应商
  post_plat_index_supplierList: data => post('/plat/index/supplierList', data),
  // 供应商商品列表
  get_supplier_product_list:  params => get( "/supplier/product/list" , {params}),
  // 我的订单列表
  plat_order_myOrder_page:  data => post( '/plat/order/myOrder/page', data),
  // 订单收货
  plat_order_receive:  data => post( '/plat/order/receive', data),
  // 订单评价
  plat_order_commentOrder:  data => post( '/plat/order/commentOrder', data),
  // 查看订单评价
  plat_order_examineOrderComment:  params => get( "/plat/order/examineOrderComment" , {params}),
  // 取消订单
  plat_order_cancel:  data => post( '/plat/order/cancel', data),
  // 售后
  plat_order_after_sale:  data => post( '/plat/order/after/sale', data),


  // 我的订单详情数据
  plat_order_myOrder_detail:  params => get( '/plat/order/myOrder/detail', {params}),

  // 我的供应商
  post_buyer_supplier_company_supplier:  data => post( '/buyer/supplier/company/supplier' , data),
  // 我的收藏列表
  post_buyer_product_company_favorites_list:  data => post( '/buyer/product/company/favorites/list' , data),
  // 学校身份的我的收藏列表
  post_college_product_company_favorites_list:  data => post( '/buyer/product/favorites/list' , data),
   // 企业账期管理列表
   get_getPaymentDaysList_list:  params => get( "/settlement/period/config/getPaymentDaysList" , {params}),
  //供应商列表
  get_platSupplierList_list: params => get( "/supplier/platSupplierList" , {params}),
  //供应商对应的商品
  get_ProductList_list: params => get( "/supplier/product/getProductList2" , {params}),
  //添加账期
  add_account_apply:  data => post( '/settlement/period/config/apply' , data),
  //编辑账期
  edit_account_apply:  data => put( '/settlement/period/config/modify' , data),
  //驳回状态时编辑账期
  submitApply_account_apply:  data => put( `/settlement/period/config/submitApply/${data.id}`),
  //删除账期
  del_account_remove:  data => del( `/settlement/period/config/remove/${data.id}`),
  //修改开启状态
  changeState_account_apply:  data => post( `/settlement/period/config/changeState/${data.id}`),
  //获取企业信用额度
  get_EnterpriseQuota_list: params => get( "/settlement/period/config/queryEnterpriseQuota" , {params}),
  // 查询订单支付信息
  plat_order_queryOrderPayInfo:  params => get( '/plat/order/queryOrderPayInfo', {params}),
  
  // 账期打款头部信息
  buyer_settlement_period_getHeadData:  data => post( '/buyer/settlement/period/getHeadData', data),
  // 账期打款分页数据
  buyer_settlement_period_pageList:  data => post( '/buyer/settlement/period/pageList' , data),
  // 我的财务页面
  plat_order_queryMyFinance:  data => post( '/plat/order/queryMyFinance' , data),
  // 我的财务页面头部信息
  plat_order_queryMyFinanceHead:  data => post( '/plat/order/queryMyFinanceHead' , data),
  // 退款
  plat_order_productRefundApply:  data => post( '/plat/order/productRefundApply/'+data , ),


  // 获取买方UserSign
  info_buyer_getUserSign:  params => get( '/sysUser/buyer/getUserSign' , {params}),
  // 获取供应商IM
  info_supplier_getUserSign:  params => get( '/sysUser/supplier/getUserSign' , {params}),
  // 订单商品校验
  plat_order_valid:  data => post( '/plat/order/valid' , data),
  // 订单概括
  buyer_order_list:  params => get( '/buyer/order/list' , {params}),
  // 高校账号热销商品
  buyer_product_list:  params => get( '/buyer/product/list' , {params}),
  // 高校商城商品列表
  buyer_product_list2:  params => get( '/buyer/product/list2' , {params}),
  //高校获取商品分类
  buyer_category_list:  params => get( '/buyer/product/category/list' , {params}),
  // 高校账号我的供应商
  buyer_supplier_mySupplierList:  params => get( '/buyer/supplier/mySupplierList' , {params}),
  // 电子台账概览-集采中心
  buyer_order_plat_outline:  params => get( '/buyer/order/plat/outline' , {params}),
  // 供应商对应商品种类列表
  supplier_product_getProductList:  params => get( '/supplier/product/getProductList' , {params}),
  //获取高校下的供应商列表
  get_buyer_supplierList:  params => get( '/buyer/supplier/today/list' , {params}),
  //根据账期id查询关联订单列表
  get_buyer_settlement_period_queryOrderList:  params => get( '/buyer/settlement/period/queryOrderList' , {params}),
  //根据账期id查询关联订单列表
  get_buyer_settlement_period_queryDeliveryDataByOrderCode:  params => get( '/buyer/settlement/period/queryDeliveryDataByOrderCode' , {params}),
  //订单明细
  get_buyer_settlement_period_order_details:  params => get( '/buyer/settlement/period/order/details' , {params}),
  // 企业账期——发起支付
  settlement_postPayOrder:  data => post( "/buyer/settlement/period/request" , data),
  // 企业账期——账期线下打款提交凭证
  buyer_settlement_period_uploadProof:  data => post( "/buyer/settlement/period/uploadProof" , data),
  // 企业账期——线下打款
  buyer_settlement_period_offlinePay:  data => post( "/buyer/settlement/period/offlinePay" , data),
  // 订单配送费校验
  plat_order_delivery_valid:  data => post( "/plat/order/delivery/valid" , data),

}
export default api
