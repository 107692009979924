import storage from '@/utils/storage'

const city = {
  state() {
    return {
      cartPosition: storage.get('cartPosition') || 200
    }
  },
  mutations: {
    SAVE_cartPosition(state, cartPosition) {
      state.cartPosition = cartPosition
      storage.set('cartPosition', cartPosition)
    }
  }
}

export default city
