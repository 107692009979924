import storage from '@/utils/storage'

const submitOrder = {
  state() {
    return {
      submitOrder: storage.get('submitOrder') || []
    }
  },
  mutations: {
    SAVE_submitOrder(state, submitOrder) {
      state.submitOrder = submitOrder
    },
  },
  actions: {
    set_submitOrder({ state, commit }, payload) {
      commit('SAVE_submitOrder', payload)
      storage.set('submitOrder', payload)
    },

    clear_submitOrder({ state, commit }) {
      commit('SAVE_submitOrder', [])
      storage.set('submitOrder', [])
    },
  }
}

export default submitOrder
