import { createStore } from 'vuex'
import token from './token'
import user from './user'
import city from './currentCity'
import cartPosition from './cartPosition'
import productsCart from './productsCart'
import submitOrder from './submitOrder'
import district from './districtTree'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    token,
    user,
    city,
    cartPosition,
    productsCart,
    submitOrder,
    district
  }
})
