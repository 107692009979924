import storage from '@/utils/storage'
import api from '@/net/api'

const district = {
  state() {
    return {
      districtTree: []
    }
  },
  mutations: {
    SET_districtTree(state, payload) {
      state.districtTree = payload
    }
  },
  actions: {
    set_districtTree({ state, commit }, payload) {
      commit('SET_districtTree', payload)
    },

    clear_districtTree({ state, commit }) {
      commit('SET_districtTree', [])
    },

    get_districtTree({ state, dispatch }) {
      api.get_plat_districtTree().then(res => {
        dispatch('set_districtTree', res || [])
      })
    }
  }
}

export default district
