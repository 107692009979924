import { API_BASEURL } from '@/constant'
import { message } from 'ant-design-vue'
import store from '@/store'
import axios from 'axios'
import util from '@/utils'
import router from '@/router'
import _ from 'lodash'

// 1.创建类的实例对象
const instance = axios.create({})

// 2.设置api的根路径
switch (process.env.NODE_ENV) {
  case 'development':
  case 'production':
    instance.defaults.baseURL = API_BASEURL
    break
  default:
    break
}

// 3.当前请求为跨域类型时是否在请求中协带cookie。
instance.defaults.withCredentials = true

// 4.请求拦截器（添加token）
instance.interceptors.request.use(config => {
  config.headers.endpoint = 'PC'
  config.headers.appVersion = '1.0.0'
  config.headers.Token = store.state.token.token
  return config
})

// 5.响应拦截器（处理http异常；处理api异常；返回正常data）
instance.interceptors.response.use(
  // 处理正确的网络返回
  res => {
    if (res.status == 200) {
      return res
    }
  },
  // 处理http异常
  err => {
    if (err?.response?.status) {
      // 处理不同http状态码
      let { status } = err.response
      if (status === 401) {
        message.warning('登录失效,请重新登录')
        store.commit('CLEAR_token')
        store.dispatch('clear_userInfo')
        store.dispatch('clear_navsList')
        store.dispatch('clear_productsCart')
        router.replace('/login')
      } else if (status === 502) {
        message.warning('服务器正在部署，请稍后~')
      } else {
        message.warning('服务异常，状态码：' + status)
      }
    } else {
      // 不能处理状态码
      message.warning('服务异常')
    }
    return false
  }
)

// 导出实例
export default instance

function request({ type = 'get', args }) {
  return new Promise((resolve, reject) => {
    instance[type](...args).then(res => {
      // >>必须这样写，不能改成!data
      if (res !== false) {
        // 需要自己单独处理code码
        if (args[2]?.needHandle) {
          resolve(res.data)
        } else {
          let { code, data, msg } = res.data
          if (code !== 0) {
            message.warning(msg || '网络异常')
            reject(res.data)
            return false
          }
          resolve(data)
        }
      } else {
        reject(res.data)
        // throw new Error('内部错误')
      }
    })
  }).catch(e => {
    // 在此可以写处理错误的公共方法
    // console.log('catch error', e)
    
    // throw e 可将错误抛到页面中，页面中.catch接收错误
    throw e
  })
}

// 导出get方法
export function get(...args) {
  util.clearParamsTrim(args[1])
  return request({ type: 'get', args })
}
// 导出post方法
export function post(...args) {
  util.clearParamsTrim(args[1])
  return request({ type: 'post', args })
}

// 导出post方法
export function put(...args) {
  return request({ type: 'put', args })
}

export function del(...args) {
  return request({ type: 'delete', args })
}