export const storageInstanceMap = {}
export const storageBaseName = 'rdjcWebsite'

export class Storage {
  baseName = storageBaseName
  storageType = 'localStorage'

  constructor(config) {
    if (config?.baseName) {
      this.baseName = config.baseName
    }
    if (config?.storageType) {
      this.storageType = config.storageType
    }
    storageInstanceMap[this.baseName] = this
  }

  get(key, defaultValue) {
    let result
    let val = window[this.storageType].getItem(`${this.baseName}_${key}`)
    if (val) {
      result = JSON.parse(val)
    } else {
      result = defaultValue || val
    }
    return result
  }

  set(key, value) {
    let str = ''
    if (value != undefined) {
      str = JSON.stringify(value)
    }
    window[this.storageType].setItem(`${this.baseName}_${key}`, str)
  }
}

export function getStorage(baseName) {
  return storageInstanceMap[baseName || storageBaseName] || new Storage()
}

const storage = getStorage()
export default storage
