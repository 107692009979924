import storage from '@/utils/storage'
import api from '@/net/api'

const token = {
  state() {
    return {
      token: storage.get('token') || ''
    }
  },
  mutations: {
    SET_token(state, payload) {
      state.token = payload
      storage.set('token', payload)
    },

    CLEAR_token(state, payload) {
      state.token = ''
      storage.set('token', '')
    }
  },
  actions: {}
}

export default token
