// 判断是否是 null, undefined ''
function isEmptyFun(val) {
  return [undefined, null, ''].includes(val)
}

export function clearParamsTrim(params) {
  if (isEmptyFun(params)) {
    return params
  }
  if (Array.isArray(params)) {
    return params.map(item => {
      clearParamsTrim(item)
    })
  }
  if (typeof params == 'object' && !isEmptyFun(params)) {
    let keys = Object.keys(params)
    keys.map(key => {
      if (typeof params[key] === 'string') {
        params[key] = params[key].trim()
      } else if (typeof params[key] === 'object' && !isEmptyFun(params[key])) {
        clearParamsTrim(params[key])
      }
    })
    return
  }
  if (typeof params === 'string') {
    params = params.trim()
  }
}

// 把数字转换成精确到小数点后两位
export function toFixed2(total) {
  if (isNaN(parseFloat(total))) {
    return total
  }
  return ((parseFloat(_.ceil(_.round(total, 2))) || 0) / 100).toFixed(2)
}

// 隐藏中间名称*
export function hideMiddleString(str='') {
  return str.charAt(0)+'***'+str.charAt(str.length-1)
}
// 默认图片地址
export function imgMorenUrl() {
  let r = ''
  switch (process.env.NODE_ENV) {
    case 'development':
      r = 'images/USER_HEAD_IMG/8234e06cdc054e5dae364eb4a0a1fe9a.png'
      break
    case 'production':
      if (location.host == 'test.rongdaufun.com') {
        r = 'images/USER_HEAD_IMG/8234e06cdc054e5dae364eb4a0a1fe9a.png'
      } else {
        r = 'images/ORG_LOGO_IMG/d39fa0995e44439993807daa88a83744.png'
      }
      break
    default:
      break
  }
  return r
}

// 转换图片的路径为全路径
export function fullImage(src) {
  let r = src
  switch (process.env.NODE_ENV) {
    case 'development':
      r = 'https://tcdn.rongdaufun.com/' + r + '?imageslim'
      break
    case 'production':
      let baseurl = 'https://cdn.rongdaufun.com/'
      if (location.host == 'test.rongdaufun.com') {
        baseurl = 'https://tcdn.rongdaufun.com/'
      }
      r = baseurl + r + '?imageslim'
      break
    default:
      break
  }
  return r
}

// 将金额转为千位格式
export function formattedOrderAmount(total){
  if (isNaN(parseFloat(total))) {
    return total
  }
  let orderAmount = ((parseFloat(_.ceil(_.round(total, 2))) || 0) / 100).toFixed(2)
  // 设置要显示的小数位数、千分符等选项（根据需求进行调整）
  const options = { minimumFractionDigits: 0, maximumFractionDigits: 2 };
  // 使用toLocaleString()函数将订单金额转换成指定格式
  return orderAmount.toLocaleString('zh-CN', options);
}
export default {
  clearParamsTrim,
  toFixed2,
  imgMorenUrl,
  fullImage,
  hideMiddleString,
  formattedOrderAmount,
}
